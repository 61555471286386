import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import WotDesign from 'wot-design'
import 'wot-design/lib/theme-default/index.css'
import Videojs from 'video.js'
import 'video.js/dist/video-js.css'
Vue.config.ignoredElements = [
        'wx-open-launch-weapp',
    ]
    // const tui = require("@/assets/js/uni.webview.js")
    // Vue.prototype.tui = tui

import Vant from 'vant';
import 'vant/lib/index.css';
import VConsole from 'vconsole';
// new VConsole();


Vue.use(Vant);
Vue.prototype.$video = Videojs
Vue.use(WotDesign)
let jweixin = require('jweixin-module')
Vue.prototype.$wx = jweixin
import Utils from './common/utils.js';
Vue.prototype.utils = Utils;
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
    ak: 'rvXjLfBhe32R82AFOtNaP2hhvc5WU2v5'
})
import VueAppend from 'vue-append'
Vue.use(VueAppend)

// 配置请求的根路径
axios.defaults.baseURL = store.state.http + '/api.php'
axios.interceptors.request.use(config => {
    if (config.method == 'post') {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    return config;
})
Vue.prototype.$http = axios




// 关闭生产模式下给出的提示
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')