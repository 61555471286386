<template>
	<div id="app">
		<keep-alive>
		    <router-view v-if="$route.meta.keepAlive"></router-view>
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive"></router-view>
	</div>
</template>

<script>
	import "@/assets/css/reset.css";
	export default {
		data() {
			return {
				
			}
		},
		created() {
			this.getConfig();
		},
		mounted() {
			if(this.$store.state.beta){
				console.log('执行state数据保存啦！');
			}
			window.addEventListener('unload', this.saveState)
		},
		methods: {
			saveState() {
				sessionStorage.setItem('state', JSON.stringify(this.$store.state));
			},
			// 获取系统配置
			async getConfig() {
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/other/config');
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('App.vue获取系统配置：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var config = res.data;
				if(config.img1 && config.img1.substring(0,4) != 'http'){
					config.img1 = this.$store.state.http + config.img1;
				}
				if(config.img2 && config.img2.substring(0,4) != 'http'){
					config.img2 = this.$store.state.http + config.img2;
				}
				if(config.logo && config.logo.substring(0,4) != 'http'){
					config.logo = this.$store.state.http + config.logo;
				}
				if(config.template && config.template.substring(0,4) != 'http'){
					config.template = this.$store.state.http + config.template;
				}
				if(config.advert){
					if(config.advert.img && config.advert.img.substring(0,4) != 'http'){
						config.advert.img = this.$store.state.http + config.advert.img;
					}
				}
				document.title = config.title;
				document.querySelector('meta[name="keywords"]').setAttribute('content',config.keywords);
				document.querySelector('meta[name="description"]').setAttribute('content',config.description);
				this.$store.commit("saveConfig", config);
			},
		},
	}
</script>

<style>
*{
	-webkit-touch-callout: none; /*系统默认菜单被禁用*/
  -webkit-user-select: none; /*webkit浏览器*/
  -khtml-user-select: none; /*早起浏览器*/
  -moz-user-select: none; /*火狐浏览器*/
  -ms-user-select: none; /*IE浏览器*/
  user-select: none; /*用户是否能够选中文本*/
}
input,select {
  -webkit-user-select:auto; /*webkit浏览器*/
  user-select: auto; /*用户是否能够选中文本*/
}
	body{
		background: #f8f8f8;
	}
	.blank_100{
		height: 1rem;
	}
	.blank_80{
		height: 0.1rem;
	}
	.mask{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0,0,0,.55);
		z-index: 666;
	}
</style>
