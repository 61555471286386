export default {
    js_date_time(number,type) {
		if(type == 1){
			var n = number * 1000;
			var date = new Date(n);
			var Y = date.getFullYear();
			var M = date.getMonth() + 1;
			var D = date.getDate();
			if (M < 10) {
				M = '0' + M;
			};
			if (D < 10) {
				D = '0' + D;
			};
			var Time = Y + '-' + M + '-' + D;
		}
		if(type == 2){
			var n = number * 1000;
			var date = new Date(n);
			var Y = date.getFullYear();
			var M = date.getMonth() + 1;
			var D = date.getDate();
			if (M < 10) {
				M = '0' + M;
			};
			if (D < 10) {
				D = '0' + D;
			};
			var H = date.getHours();
			if (H < 10) {
				H = '0' + H;
			};
			var I = date.getMinutes();
			if (I < 10) {
				I = '0' + I;
			};
			var S = date.getSeconds();
			if (S < 10) {
				S = '0' + S;
			};
			return Y + '-' + M + '-' + D + ' ' + H + ':' + I + ':' + S;
		}
		if(type == 3){
			var n = number * 1000;
			var date = new Date(n);
			var Y = date.getFullYear();
			var M = date.getMonth() + 1;
			var D = date.getDate();
			if (M < 10) {
				M = '0' + M;
			};
			if (D < 10) {
				D = '0' + D;
			};
			var Time = Y + '.' + M + '.' + D;
		}
		if(type == 4){
			var n = number * 1000;
			var date = new Date(n);
			var Y = date.getFullYear();
			var M = date.getMonth() + 1;
			var D = date.getDate();
			if (M < 10) {
				M = '0' + M;
			};
			if (D < 10) {
				D = '0' + D;
			};
			var H = date.getHours();
			if (H < 10) {
				H = '0' + H;
			};
			var I = date.getMinutes();
			if (I < 10) {
				I = '0' + I;
			};
			return Y + '-' + M + '-' + D + ' ' + H + ':' + I;
		}
		return Time;
    }
}