import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
    { path: '/', redirect: '/index' },
    {
        path: '/index',
        component: () =>
            import ('@/components/Index/Index.vue'),
        meta: { keepAlive: true, noNav: true }
    },
    {
        path: '/about',
        component: () =>
            import ('@/components/About/Index.vue'),
        meta: { title: '学校简介', keepAlive: true }
    },
    {
        path: '/phocall',
        component: () =>
            import ('@/components/Personal/phocall/phocall.vue'),
        meta: { title: '在线咨询', keepAlive: true }
    },
    {
        path: '/netcall',
        component: () =>
            import ('@/components/Personal/netcall/netcall.vue'),
        meta: { title: '网络咨询', keepAlive: true }
    },
    {
        path: '/contact',
        component: () =>
            import ('@/components/About/Contact.vue'),
        meta: { title: '联系方式', keepAlive: true }
    },
    {
        path: '/message',
        component: () =>
            import ('@/components/About/Message.vue'),
        meta: { title: '留言反馈', keepAlive: true }
    },
    {
        path: '/train',
        component: () =>
            import ('@/components/Train/List.vue'),
        meta: { title: '培训项目', keepAlive: true }
    },
    {
        path: '/trainInfo',
        component: () =>
            import ('@/components/Train/Info.vue'),
        meta: { title: '培训详情', keepAlive: true }
    },
    {
        path: '/news',
        component: () =>
            import ('@/components/News/List.vue'),
        meta: { title: '学校动态', keepAlive: true }
    },
    {
        path: '/guidance',
        component: () =>
            import ('@/components/guidance/List.vue'),
        meta: { title: '平台操作指导', keepAlive: true }
    },
    {
        path: '/guidanceInfo',
        component: () =>
            import ('@/components/guidance/Info.vue'),
        meta: { title: '平台操作指导详情', keepAlive: true }
    },
    {
        path: '/newsInfo',
        component: () =>
            import ('@/components/News/Info.vue'),
        meta: { title: '学校动态详情', keepAlive: true }
    },
    {
        path: '/teacher',
        component: () =>
            import ('@/components/Teacher/List.vue'),
        meta: { title: '教师风采', keepAlive: true }
    },
    {
        path: '/teacherInfo',
        component: () =>
            import ('@/components/Teacher/Info.vue'),
        meta: { title: '教师风采详情', keepAlive: true }
    },
    {
        path: '/help',
        component: () =>
            import ('@/components/Help/List.vue'),
        meta: { title: '帮助中心', keepAlive: true }
    },
    {
        path: '/helpInfo',
        component: () =>
            import ('@/components/Help/Info.vue'),
        meta: { title: '帮助中心详情', keepAlive: true }
    },
    {
        path: '/download',
        component: () =>
            import ('@/components/Download/Index.vue'),
        meta: { title: '资料下载', keepAlive: true }
    },
    {
        path: '/login',
        name: 'login',
        component: () =>
            import ('@/components/Personal/Login.vue'),
        meta: { title: '登录注册', keepAlive: true }
    },
    {
        path: '/regist',
        component: () =>
            import ('@/components/Registration/Transition.vue'),
        meta: { title: '网上报名', keepAlive: true }
    },
    {
        path: '/personal_regist',
        component: () =>
            import ('@/components/Registration/Index1.vue'),
        meta: { title: '个人报名', keepAlive: true }
    },
    {
        path: '/team_regist',
        component: () =>
            import ('@/components/Registration/Index2.vue'),
        meta: { title: '团队报名', keepAlive: true }
    },
    {
        path: '/pay_info',
        component: () =>
            import ('@/components/Registration/PayInfo.vue'),
        meta: { title: '线上缴费', keepAlive: true }
    },
    {
        path: '/pay_status',
        component: () =>
            import ('@/components/Registration/PayStatus.vue'),
        meta: { title: '缴费情况', keepAlive: true }
    },
    {
        path: '/personal',
        component: () =>
            import ('@/components/Personal/Index.vue'),
        meta: { title: '个人中心', keepAlive: true }
    },
    {
        path: '/userInfo/index',
        component: () =>
            import ('@/components/Personal/userInfo/Index.vue'),
        meta: { title: '个人信息', keepAlive: true }
    },
    {
        path: '/dataUpload/index',
        component: () =>
            import ('@/components/Personal/dataUpload/Index.vue'),
        meta: { title: '资料上传', keepAlive: true }
    },
    {
        path: '/study/study',
        component: () =>
            import ('@/components/Personal//study/study'),
        meta: { title: '学习中心', keepAlive: true }
    },
    {
        path: '/dataUpload/upload',
        component: () =>
            import ('@/components/Personal/dataUpload/Upload.vue'),
        meta: { title: '资料上传', keepAlive: true }
    },
    {
        path: '/dataReceive/index',
        component: () =>
            import ('@/components/Personal/dataReceive/Index.vue'),
        meta: { title: '教材领取', keepAlive: true }
    },
    {
        path: '/dataReceive/receive',
        component: () =>
            import ('@/components/Personal/dataReceive/Receive.vue'),
        meta: { title: '教材领取', keepAlive: true }
    },
    {
        path: '/invoiceReceipt/index',
        component: () =>
            import ('@/components/Personal/invoiceReceipt/Index.vue'),
        meta: { title: '发票领取', keepAlive: true }
    },
    {
        path: '/invoiceReceipt/receive',
        component: () =>
            import ('@/components/Personal/invoiceReceipt/Receive.vue'),
        meta: { title: '发票领取', keepAlive: true }
    },
    {
        path: '/invoice/index',
        component: () =>
            import ('@/components/Personal/invoice/Index.vue'),
        meta: { title: '开票资料', keepAlive: true }
    },
    {
        path: '/invoice/info',
        component: () =>
            import ('@/components/Personal/invoice/Info.vue'),
        meta: { title: '开票资料', keepAlive: true }
    },
    {
        path: '/order/list',
        component: () =>
            import ('@/components/Personal/order/List.vue'),
        meta: { title: '我的订单', keepAlive: true }
    },
    {
        path: '/order/info',
        component: () =>
            import ('@/components/Personal/order/Info.vue'),
        meta: { title: '订单详情', keepAlive: true }
    },
    {
        path: '/order/refund',
        component: () =>
            import ('@/components/Personal/order/Refund.vue'),
        meta: { title: '申请退款', keepAlive: true }
    },
    {
        path: '/message/list',
        component: () =>
            import ('@/components/Personal/message/List.vue'),
        meta: { title: '消息通知', keepAlive: true }
    },
    {
        path: '/message/info',
        component: () =>
            import ('@/components/Personal/message/Info.vue'),
        meta: { title: '消息通知', keepAlive: true }
    },
    {
        path: '/integral/index',
        component: () =>
            import ('@/components/Personal/integral/Index.vue'),
        meta: { title: '我的积分', keepAlive: true }
    },
    {
        path: '/integral/log',
        component: () =>
            import ('@/components/Personal/integral/Log.vue'),
        meta: { title: '积分日志', keepAlive: true }
    },
    {
        path: '/integral/list',
        component: () =>
            import ('@/components/Personal/integral/List.vue'),
        meta: { title: '积分兑换', keepAlive: true }
    },
    {
        path: '/integral/info',
        component: () =>
            import ('@/components/Personal/integral/Info.vue'),
        meta: { title: '兑换详情', keepAlive: true }
    },
    {
        path: '/learnCenter/index',
        component: () =>
            import ('@/components/Personal/learnCenter/Index.vue'),
        meta: { title: '学习中心', keepAlive: true }
    },
    {
        path: '/learnCenter/list',
        component: () =>
            import ('@/components/Personal/learnCenter/List.vue'),
        meta: { title: '学习中心', keepAlive: true }
    },
    {
        path: '/learnCenter/prove',
        component: () =>
            import ('@/components/Personal/learnCenter/Prove.vue'),
        meta: { title: '学习证明', keepAlive: true }
    },
    {
        path: '/learnCenter/video',
        component: () =>
            import ('@/components/Personal/learnCenter/Video.vue'),
        meta: { title: '学习中心', keepAlive: true }
    },
    {
        path: '/simulation/index',
        component: () =>
            import ('@/components/Personal/Simulation/Index.vue'),
        meta: { title: '模拟考试', keepAlive: true }
    },
    {
        path: '/simulation/choose',
        component: () =>
            import ('@/components/Personal/Simulation/Choose.vue'),
        meta: { title: '模拟考试', keepAlive: true }
    },
    {
        path: '/simulation/simulation',
        component: () =>
            import ('@/components/Personal/Simulation/Simulation.vue'),
        meta: { title: '模拟考试', keepAlive: true }
    },
    {
        path: '/simulation/examination',
        component: () =>
            import ('@/components/Personal/Simulation/Examination.vue'),
        meta: { title: '模拟考试', keepAlive: true }
    },
    {
        path: '/simulation/examinationStatus',
        component: () =>
            import ('@/components/Personal/Simulation/ExaminationStatus.vue'),
        meta: { title: '模拟考试', keepAlive: true }
    },
    {
        path: '/simulation/review',
        component: () =>
            import ('@/components/Personal/Simulation/Review.vue'),
        meta: { title: '模拟考试', keepAlive: true }
    },
    {
        path: '/ExaminationCenter/index',
        component: () =>
            import ('@/components/ExaminationCenter/Index.vue'),
        meta: { title: '考试中心', keepAlive: true }
    },
    {
        path: '/ExaminationCenter/lst',
        component: () =>
            import ('@/components/ExaminationCenter/List.vue'),
        meta: { title: '考试列表', keepAlive: true }
    },
    {
        path: '/Examination/index',
        component: () =>
            import ('@/components/Examination/Index.vue'),
        meta: { title: '开始考试', keepAlive: true }
    },
    {
        path: '/Examination/status',
        component: () =>
            import ('@/components/Examination/Status.vue'),
        meta: { title: '考试结果', keepAlive: true }
    },
    {
        path: '/video',
        component: () =>
            import ('@/components/Video/List.vue'),
        meta: { title: '公益视频', keepAlive: true }
    },
]
const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) { // 页面缓慢到顶
        let distance = document.documentElement.scrollTop || document.body.scrollTop;
        let step = distance / 0;
        (function jump() {
            if (distance > 0) {
                distance -= step;
                window.scrollTo(0, distance);
                setTimeout(jump, 10);
            }
        })();
    }
})
export default router