import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: sessionStorage.getItem('state') ? JSON.parse(sessionStorage.getItem('state')) : {
        http: 'https://tanpei.ks265.net',
        beta: document.domain == 'localhost' ? true : false,
        // userInfo: window.localStorage.getItem("userInfo") ? JSON.parse(window.localStorage.getItem("userInfo")) : "",
        userInfo: window.localStorage.getItem("userInfo") ? window.localStorage.getItem("userInfo") : "",
        cardid: window.localStorage.getItem("cardid") ? JSON.parse(window.localStorage.getItem("cardid")) : "",
        // userInfo: '',
        // cardid: '',
        overTime: '',
        configInfo: '',
        overDay: 7 * 24 * 60 * 60,
        uid: '',
        eduList: ['初中', '高中', '中专/技校', '大专', '本科', '硕士', '博士']
    },
    mutations: {
        saveUid(state, uid) {
            state.uid = uid
        },
        saveOverTime(state, overTime) {
            state.overTime = overTime
        },
        saveUserInfo(state, userInfo) {
            state.userInfo = userInfo;
        },
        saveCardId(state, cardid) {
            state.cardid = cardid;
        },
        saveConfig(state, config) {
            state.configInfo = config;
        }
    },
    actions: {},
    modules: {}
})